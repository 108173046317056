<template>
    <div>
         <form @submit.prevent="handleSubmit" class="flex flex-col space-y-3" autocomplete="off">
            <input class="rounded bg-yellow-100 p-2 focus:border-none focus:bg-green-100" type="text" required placeholder="your NFTy's nickname (be creative 😎)" v-model="displayName">
            <input class="rounded bg-yellow-100 p-2 focus:border-none focus:bg-green-100" type="email" required placeholder="email" v-model="email">
            <input class="rounded bg-yellow-100 p-2 focus:border-none focus:bg-green-100" type="password" required placeholder="password" v-model="password">
            <input class="rounded bg-yellow-100 p-2 focus:border-none focus:bg-green-100" type="text" placeholder="invite code" v-model="invitecode">
            <button class="rounded shadow bg-yellow-500 font-bold disabled:bg-gray-200 p-2 focus:border-none transition transform hover:scale-110 hover:shadow-xl hover:bg-green-300">sign up for early access </button>
         </form>
    </div>
</template>

<script>
import {ref} from 'vue'

export default {
    setup () {
        //refs
        const displayName = ref('')
        const email = ref('')
        const password = ref('')
        const invitecode = ref('')

        const handleSubmit = () => {
            console.log(displayName.value, email.value, password.value, invitecode.value)
        }
        
        return {displayName, email, password, invitecode, handleSubmit}
    }
}
</script>

<style lang="scss" scoped>

</style>