<template>
  <div class="about">
    <h1>You need to login to view content.</h1>



  </div>
</template>

<script>
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";



export default {
  setup () {
    

//  Wrap with Web3Provider from ethers.js
const web3Provider = new providers.Web3Provider(provider);
    //  Create WalletConnect Provider
const provider = new WalletConnectProvider({
  infuraId: "0a8e8b4f8c7c4df9a86961eda8ef8f41",
});


const load = async () => {
    try {
          // const balance = await provider.getBalance(contractAddress)
          // console.log("BALANCE: "+ balance)

          //  Enable session (triggers QR Code modal)
          await provider.enable();

          provider.on("accountsChanged",(accounts) => {
            console.log(accounts);
          })
          provider.on("chainChanged",(chainId) => {
            console.log(chainId);
          })
          provider.on("disconnect",(code,reason) => {
            console.log(code, reason)
          })


          
    } catch (err) {
         console.log("cannot fetch balance")
    }
}
load()



    return {provider}
  }
}
</script>

<style lang="scss" scoped>

</style>