// import firebase from 'firebase/app'
//  import 'firebase/firestore'
//  import 'firebase/auth'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
        apiKey: "AIzaSyCz5UjkN6Su7utEbfHJh_lzT2-pZnn0Tds",
        authDomain: "earlyaccessnft.firebaseapp.com",
        projectId: "earlyaccessnft",
        storageBucket: "earlyaccessnft.appspot.com",
        messagingSenderId: "725284288817",
        appId: "1:725284288817:web:c57d8559b99ab4ae3d9810",
        measurementId: "G-MFS469VBX2"
    };

// init firebase
firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectAuth, projectFirestore, timestamp }
