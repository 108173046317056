<template>
  <div class="earlyaccess center">
    <div class=" py-2 flex flex-col justify-center sm:py-2">
      <div class="relative py-3 sm:max-w-xl sm:mx-auto">
        <div
          class="absolute inset-0 bg-gradient-to-r from-yellow-400 to-gray-100 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-2 sm:rounded"
        ></div>
        <div class="relative px-4 py-2 bg-white shadow-lg sm:rounded sm:p-20">
          <div class="max-w-xs mx-auto">
            <div>


                          <div class="flex flex-col space-y-7 mx-auto">
                                    <p class="text-justify mx-auto items-center">
                                          <span class="font-bold text-yellow-700">
                                              NFTy.today</span
                                            >'s early access works with an invite code. If you received an invite code,
                                            please enter it below:
                                    </p>


                                    <SignupForm />
                                 

                                 
                          </div>
 

            </div>
            <div class="divide-y divide-gray-200"></div>
          </div>
        </div>
      </div>
    </div>

 
  </div>
   <p class="text-justify  max-w-screen-lg mx-auto max-w-full my-10 text-sm ">
                                    No access code? You can always ask a friend that's already an early member of NFTy. If you have <b>no code</b>, <u>do <b>no</b>t <b>FOMO</b>, 
                                    you will automatically join our waiting list</u>.
                                    
                                    In order to keep our servers running in normal parameters we will allow maximum 20000 requests per day. Please do not request access on
                                    discord, this process is fully automated. You will have access to a pool of fresh collections to mint 1<sup>st</sup> in a few days after your request. 
                                    
                                  </p>
</template>

<script>
import { defineComponent, ref, watch, watchEffect } from "vue";
import { useToast } from "vue-toastification";
import useSignup from '../composables/useSignup'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import SignupForm from '../components/SignupForm'

export default defineComponent({
  name: "EarlyAccess",
  components: {SignupForm},
  data() {
    return {
      form: {
        email: '',
        thePass: '',
        theConfPass: '',
      }
    }
  },
  setup() {
    // Get toast interface
    const toast = useToast()
    const theMailR = ref("")
    const theCodeR = ref("")
    const thePassR = ref("")
    //const {error, signup } = useSignup()

    const {error, signup } = useSignup()


    const getIt = () => {
      //toast.info("mail: " + theMailR.value);
      //toast.warning("code:" + theCodeR.value);
      if ((theCodeR.value == "") || (theMailR.value == "")) {
        toast.warning("Please complete EMAIL, INVITE CODE and password fields");
        //toast.info("well: | "+validateEmail(theMailR.value)+"| ----")
      } else {
        if (validateEmail(theMailR.value)) { toast.warning("EMAIL is not correct"); } else 
        toast.success("Adding you on the list...");
      }
    };

    watch(theMailR, () => {
      console.log("watch ran");
    });

    const stopEff = watchEffect(() => {
      //console.log("w effect ran", theCodeR.value);
    });

    const handleBut = () => {
      //stopEff();
    };

const validateEmail = (email) => {
 
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());

}

/*
 // Dummy delay
      setTimeout(async () => {
        // Send login request
        
        await signup(theMailR.value, theCodeR.value, displayName.value)
       
          if (error.value == null) {
            console.log('user signed up')
            router.push({ name: "dashboard" })
            } else {
                    //console.log("ERROARE! "+error.value) 
              toast.error(error.value,{
  // override the global option
  position: "bottom-right",
  pauseOnHover: true
});

}
       

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    
*/
    return { toast, theMailR, theCodeR, getIt, handleBut,v$: useVuelidate() };
  },

  validations() {
    return {
      form: {
        email: { required, email },
        password: { required, min: minLength(6) },
        //confirmPassword: { required }
      },
    }
  },

});
</script>
