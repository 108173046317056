<template>
    <div class="mx-auto my-auto">
        Ether Collection: {{contractAddress}}
        <hr/>
<vue-typed :strings="['Hello', 'World']" />
    </div>
</template>

<script>
import { BOYC } from "../assets/abi/BOYC";
import {ref,defineComponent} from 'vue'
import { VueTyped } from "vue3-typed.js"

export default defineComponent({
     components: {
    VueTyped
  },
    name: "ether_collections",
    setup () {
        const ethers = require('ethers');        
        const HDWalletProvider = require('@truffle/hdwallet-provider');
        const mnemonic = 'cheese exhaust bid car attack ginger either just gain pudding sorry lonely'
        const wallet = new HDWalletProvider(mnemonic, 'https://mainnet.infura.io/v3/0a8e8b4f8c7c4df9a86961eda8ef8f41')
        const contractAddress =  "0xa66cc78067fd1e6aa3eec4ccdff88d81527f92c1" //"0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"
        const contractABI =  BOYC


        const options = {
                        strings: ["Hello", "World", "This is vue3-typed-js"],
                        loop: true,
                        typeSpeed: 30,
                        };


        const error = ref("")
        //const balance = await provider.getBalance(address);
        
        let provider = new ethers.providers.InfuraProvider("mainnet","0a8e8b4f8c7c4df9a86961eda8ef8f41");
    

const load = async () => {
    try {
          const balance = await provider.getBalance(contractAddress)
          console.log("BALANCE: "+ balance)
    } catch (err) {
         console.log("cannot fetch balance")
    }
}
load()
        

        // const signer = provider.getSigner();

        // const NFTContract = new ethers.Contract(contractAddress, contractABI, signer);

        //const gas = await NFTContract.estimateGas.set(number);

      ///  const baseURI = await NFTContract.MAXAPES;

        //const provider = new ethers.providers.Web3Provider(window.ethereum);
        return {contractAddress, contractABI}
    }
})
</script>

<style lang="scss" scoped>

</style>